import * as React from "react"
import Header from "../../../components/Header";
import '../../../templates/styles.scss';

// markup
const ZoomPage = () => {

  const country = "en";
  const language = "en";

  return (
    <>
      <Header language={language} country={country} urlParts={["/jp/zoom_signup"]} />
      <div className="home-wrapper" style={{textAlign: "center"}}>
        <h1>Box office is not open, check back later (jp)</h1>        
      </div>
    </>    
  )
}

export default ZoomPage
